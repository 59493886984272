// import React from "react"

// // import Layout from "../components/layout"
// // import SEO from "../components/seo"

// const NotFoundPage = () => (
//   <Layout>
//     <SEO title="404: Not found" />
//     <h1>NOT FOUND</h1>
//     <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
//   </Layout>
// )

// export default NotFoundPage


import React from 'react';
import styled from 'styled-components';

const NotFoundPage = () => (
  <div className="container">
    <Head>PAGE NOT FOUND</Head>
    <P>The page you are looking for no longer exists :(</P>
  </div>
);

export default NotFoundPage;

const Head = styled.h1`
  margin-top: 50px;
`;

const P = styled.p`
  margin-bottom: 50px;
`;